import SEO from '@components/SEO';
import IconArrow3 from '@icons/IconArrow3';
import Footer from '@views/default-layout/footer/Footer';
import { Col, Row } from 'antd';
import React, { memo } from 'react';

const ShippingPolicy = memo(() => {
   return (
      <>
         <SEO title={'Shipping Policy'} />
         <Row gutter={30} justify="center">
            <Col xl={16} lg={24} md={24} sm={24} xs={24}>
               <div className="hard-content-page">
                  <div className="title">
                     <span>Shipping Policy</span>
                     <span className="s-arrow animate__animated animate__rotateInDownLeft animate__slow">
                        <IconArrow3 />
                     </span>
                  </div>
                  <div className="phase">
                     <span style={{ marginBottom: '12px', display: 'block' }}>
                        Please allow 2-3 business days for your for your item to ship.
                     </span>
                     <span style={{ marginBottom: '12px', display: 'block' }}>
                        Shipping Standard (21 - 30 Business Days)
                     </span>
                     <span style={{ marginBottom: '12px', display: 'block' }}>
                        Shipping Fast (2 - 7 Business Days)
                     </span>
                     <span style={{ marginBottom: '12px', display: 'block' }}>
                        Delivery times are estimated and not guaranteed.
                     </span>
                  </div>
               </div>
            </Col>
         </Row>
         <Footer />
      </>
   );
});

export default ShippingPolicy;
