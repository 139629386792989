import React from 'react';

const IconArrow3 = () => (
   <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M26.0002 21.7576L4.12155 -0.121094L-0.121094 4.12155L21.7576 26.0002H0.000226498V32.0002H29.0002H32.0002V29.0002V0.000226498H26.0002V21.7576Z"
         fill="currentColor"
      />
   </svg>
);

export default IconArrow3;
